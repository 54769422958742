import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
const logo =
  "https://bbt-reference-public.s3.us-east-2.amazonaws.com/build-blue-tools.png";
class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-all">
        <ul className="footer-list">
          <li className="footer-logo">
            <a href="/home">
              <img src={logo} alt="" className="footer-icon"></img>
            </a>
          </li>
          <li className="privacy-contact-footer">
            <ContactUsAndPrivacyPolicy
              contactUs="CONTACT US"
              text38="|"
              privacyPolicy="PRIVACY POLICY"
            />
            <div className="copyright raleway-medium-white-22px">
              © 2021 Build Blue Tools
            </div>
          </li>
          {/* Changelog: Jim - Adding a blank list for centered spacing */ }
          <li className="footer-last-third"/>
        </ul>
        <ContactUsAndPrivacyPolicyMobile
          contactUs="CONTACT US"
          privacyPolicy="PRIVACY POLICY"
        />
      </footer>
    );
  }
}
export default Footer;

function ContactUsAndPrivacyPolicy(props) {
  const { contactUs, text38, privacyPolicy } = props;

  return (
    <div className="contact-us-and-privacy-policy">
      <a className="contactus raleway-medium-white-22px" href="/contactus">
        {contactUs}
      </a>
      <div className="divider raleway-medium-white-22px">{text38}</div>
      <a
        className=" privacy-policy raleway-medium-white-22px"
        href="/privacypolicy"
      >
        {privacyPolicy}
      </a>
    </div>
  );
}

function ContactUsAndPrivacyPolicyMobile(props) {
  const { contactUs, privacyPolicy } = props;

  return (
    <Container className="contact-us-and-privacy-policy-mobile">
      <Row className="privacy-policy-row">
        <Col sm={6}>
          <a className="contactus raleway-medium-white-17px" href="/contactus">
            {contactUs}
          </a>
        </Col>
        <Col sm={6}>
          <a
            className=" privacy-policy raleway-medium-white-17px"
            href="/privacypolicy"
          >
            {privacyPolicy}
          </a>
        </Col>
      </Row>
      <Row className="copyright-row">
        <Col sm={8}>
          <p className="copyright raleway-medium-white-17px">
            © 2021 Build Blue Tools
          </p>
        </Col>
      </Row>
    </Container>
  );
}

