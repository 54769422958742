import React, { Component } from 'react';
import { CircularProgress } from "@material-ui/core"
import './loading.css'

class Loading extends Component {
    render() {
        return (
            <div className='loading'>
                <CircularProgress className="progress" />
            </div>
        );
    }
}

export default Loading;