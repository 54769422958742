/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import './category-page.css'
import VendorBox from '../../components/vendor-box/vendor-box'
import { Container, Row, Col } from 'react-bootstrap';
import CategoryText from '../../components/category-text/category-text';


function CategoryPage() {

    const [category , setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const results = location.state.results
    const logo = "https://bbt-reference-public.s3.us-east-2.amazonaws.com/build-blue-tools.png"

    let resultRow

    /* 
    The following block of code is the logic that determines the way vendor cards populate the page per Jen's specs.
    Right now there is a bug that repeats the last row in the prodction server.
    */

    // if (results.length % 3 === 1) { 
    //     resultRow = 
    //         <Row className="results-single">
    //             <VendorBox 
    //                 vendorName={results[0].vendorName}
    //                 vendorUrl={results[0].vendorUrl}
    //                 logoS3={results[0].logoS3}
    //                 tagline={results[0].tagline}
    //                 mission={results[0].mission}
    //             />
    //         </Row>
    // }
    
    // else if (results.length % 3 === 1 && results.length > 1) {
    //     const lastResults = results[results.length - 1]
    //     results.pop()
    //     resultRow = 
    //     <Row className="results">
    //         {results.map(result => 
    //             <Col className="result-card" md={4} lg={4} ><VendorBox 
    //                 vendorName={result.vendorName}
    //                 vendorUrl={result.vendorUrl}
    //                 logoS3={result.logoS3}
    //                 tagline={result.tagline}
    //                 mission={result.mission}
    //             /></Col>
    //         )}
    //         <Col className="result-card" md={12} lg={12} ><VendorBox 
    //                 vendorName={lastResults.vendorName}
    //                 vendorUrl={lastResults.vendorUrl}
    //                 logoS3={lastResults.logoS3}
    //                 tagline={lastResults.tagline}
    //                 mission={lastResults.mission}
    //             /></Col>

    //         <Col className="result-card" md={4} lg={4} >
    //             <img src={logo} alt='' className="result-icon" />
    //         </Col>
    //         <Col className="result-card" md={4} lg={4} ><VendorBox 
    //                 vendorName={lastResults.vendorName}
    //                 vendorUrl={lastResults.vendorUrl}
    //                 logoS3={lastResults.logoS3}
    //                 tagline={lastResults.tagline}
    //                 mission={lastResults.mission}
    //         /></Col>
    //         <Col className="result-card" md={4} lg={4} >
    //             <img src={logo} alt='' className="result-icon" />
    //         </Col>
    //     </Row>
    //     }

    //     else if (results.length % 3 === 2) {
    //         resultRow =
    //         <Row className="results">
    //             {results.map(result => 
    //                 <Col className="result-card" key={result.vendorName} md={4} lg={4} ><VendorBox 
    //                     vendorName={result.vendorName}
    //                     vendorUrl={result.vendorUrl}
    //                     logoS3={result.logoS3}
    //                     tagline={result.tagline}
    //                     mission={result.mission}
    //                 /></Col>
    //             )}
    //                 <Col className="result-card" md={4} lg={4} >
    //                     <img src={logo} alt='' className="result-icon" />
    //                 </Col>
    //         </Row> 
    //     }

        // else {
            resultRow = <Row className="results">
                {results.map(result => 
                    <Col className="result-card" key={result.vendorName} xs={1} md={4} lg={4} ><VendorBox 
                        vendorName={result.vendorName}
                        vendorUrl={result.vendorUrl}
                        logoS3={result.logoS3}
                        tagline={result.tagline}
                        mission={result.mission}
                    /></Col>
                )}
            </Row> 
        // }

        return (
            <div>
                <Container className='category-page'>
                    <CategoryText category={location.state.category} subCategory={location.state.subCategory} />
                    <br />
                    <hr width="800px"/>
                    {resultRow}
                </Container>
            </div>
        );
    }


export default CategoryPage;