import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, withStyles } from "@material-ui/core";
import "./login-button.css"

const StyledButton = withStyles ({
    root: {
      background: '#FFDB07',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 48,
      padding: '0 30x',
      boxShadow: '0 3px 5px 2px rgba(80,80,80, .3)',
      "&:hover": {
            background: '#FFDB07',
            opacity: "0.8"
      }
    },
    label: {
        fontFamily: 'Raleway',
        textTransform: 'capitalize',
        color: '#221f4b'
        
    },
})(Button)


const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <StyledButton
        className="btn-margin"
        onClick={() => loginWithRedirect()}
        id="qsLoginBtn"
        variant="outlined"
        color="primary"
        >
            LOGIN OR SIGNUP
        </StyledButton>
    );
};

export default LoginButton
