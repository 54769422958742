import React from 'react'
import './Navbar.css'
import LoginButton from "../login-button/login-button"
import LogoutButton from "../logout-button/logout-button"
import { CircularProgress } from "@material-ui/core"
import { useAuth0 } from "@auth0/auth0-react"

const logo = "https://bbt-reference-public.s3.us-east-2.amazonaws.com/build-blue-tools.png"
const AuthNav = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    if (isLoading) {
        return(<CircularProgress/>)
    }
    if (isAuthenticated) {
        return (<LogoutButton></LogoutButton>)  
    } else {
        console.log(isAuthenticated)
        return (<LoginButton></LoginButton>)
    }
}
class Navbar extends React.Component {
    render() {
        return(
           <nav className="Navbar">
               <ul className="navbar-list">
                   <li className="navbar-logo"><a href="/home"><img src={logo} alt='' className="navbar-icon"></img></a></li>
                   <li className="login-btn"><AuthNav /></li>
                </ul>
           </nav> 
        )
    }
}
export default Navbar