import './App.css';
import { Route } from 'react-router-dom'
// import Homepage from "./views/homepage/homepage"
import { Container } from 'react-bootstrap';
import LandingPage from './views/landing-page/Landing-Page';
import Navbar from "./components/navbar/Navbar"
import Footer from "./components/footer/Footer"
import Homepage from './views/homepage/homepage';
import SearchPage from './views/search-page/search-page';
import CategoryPage from './views/category-page/category-page';
import ContactPage from './views/contact-page/contact-page';
import PrivateRoute from './private-route'
import PrivacyPolicy from './views/privacy-policy/privacy-policy';

const App = props => {
  return (
    <div className="App">
      <Container className="container">
        <Navbar></Navbar>
              <Route path="/" exact component={LandingPage} />
              <Route path="/privacypolicy" exact component={PrivacyPolicy} />
              <Route path="/contactus" exact component={ContactPage} />
              <PrivateRoute path="/home" exact component={Homepage} />
              <PrivateRoute path="/search" exact component={SearchPage} />
              <PrivateRoute path="/category" exact component={CategoryPage} />
        <Footer></Footer> 
      </Container>
    </div>
  );
}

export default App;
