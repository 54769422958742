import React from "react"
import "./Landing-Page.css"
import { Container } from "react-bootstrap"
import NonMobile from "./non-mobile/non-mobile"

function LandingPage() {
    return (
        <Container className="landing-page" fluid>
            <NonMobile />
        </Container>
    )
} 
export default LandingPage