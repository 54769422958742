import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Input, TextField, Button } from "@material-ui/core"
import MuiPhoneNumber from 'material-ui-phone-number'
import ContactText from '../../components/contact-text/contact-text';
import axios from 'axios'
import "./contact-page.css"

class ContactPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            organization: '',
            phoneNumber: '',
            subject: '',
            message: '',
            submissionError: false,
            errorString: ''
        }
        this.validateSubmit = this.validateSubmit.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        // this.ErrorMessage = this.ErrorMessage.bind(this)
    }


    validateEmail() {
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.state.email)
    }

    validateSubmit() {
        console.log('VALID')
        const errors = []

        if (this.state.firstName === '') {
            errors.push('First Name')
        }
        if (this.state.lastName === '') {
            errors.push('Last Name')
        }
        if (!this.validateEmail()) {
            errors.push('Email')
        }
        if (this.state.organization === '') {
            errors.push('Organization')
        }

        if (errors.length > 0) {
            this.setState({ errorString: 'The following required fields are empty or incorrectly formatted: ' + errors.join(', ') })
            this.setState({ submissionError: true })
            return false
        } else {
            return true
        }
    }


    handleSubmit() {
        if (this.validateSubmit()) {
            console.log('Hello')
            console.log(this.state.errorString)
            axios.post('https://dumsg5gbhijmi.cloudfront.net/contactus/4871536489',
            {   
                firstName: this.state.firstName, 
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phoneNumber,
                organization: this.state.organization,
                subject: this.state.subject,
                text: this.state.message })
                .then (res => {
                    this.setState({ errorString: "Thank you for submitting! We will respond to your inquiry as soon as we can." })
                    const results = res.data
                    console.log(results)
                })
                .catch((e) => {
                    console.log(e.message)
                }) 
        }

    }

    render() {
        return (
            <div className='contact-us'>
                <Container>
                    <ContactText />
                    <hr className='line'/>
                    <div className='submission-status'>
                        <p className="raleway-medium-port-gore-17px">{this.state.errorString}</p>
                    </div>
                    <br />
                    <br />
                    <Row className='name-row'>
                        <Col md="6">
                            <Input className="firstname-field" value={this.state.firstName} variant="filled" onChange={e =>  this.setState({firstName: e.target.value})}  placeholder="First Name" />  
                        </Col>
                        <Col md="6">
                            <Input className="lastname-field" value={this.state.lastName} variant="filled" onChange={e => this.setState({lastName: e.target.value})} placeholder="Last Name" />  
                        </Col>
                    </Row>
                    <Row className='email-row'>
                        <Col md="4"> 
                            <Input className="email-field" value={this.state.email} variant="filled" onChange={e => this.setState({email: e.target.value})} placeholder="Email" />  
                        </Col>
                        <Col md="5">
                            <Input className="organization-field" value={this.state.organization} variant="filled" onChange={e => this.setState({organization: e.target.value})} placeholder="Organization" />  
                        </Col>
                        <Col md="3">
                            <MuiPhoneNumber
                                name="phone"
                                className="phone-field"
                                label="Phone Number (Optional)"
                                data-cy="user-phone"
                                onlyCountries={["us"]}
                                defaultCountry={'us'}
                                value={this.state.phoneNumber}
                                onChange={e => this.setState({phoneNumber: e.target.value})}
                            />
                        </Col>
                    </Row>
                    <Row className="subject-row">
                        <TextField className="subject-field" value={this.state.subject} label="Subject" onChange={e => this.setState({subject: e.target.value})} variant="outlined" size='small' />
                    </Row>
                    <Row className="message-row">
                        <TextField className="message-field" value={this.state.message} label="Message" onChange={e => this.setState({message: e.target.value})} variant="outlined" multiline rows={12} />
                    </Row>
                    <Row className="submit-row">
                    <Button
                        className="submit-btn"
                        id="qsLoginBtn"
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}>
                            SUBMIT
                        </Button>
                    </Row>
                    <br/>
                    <Row className="submission-status">
                        <p className="raleway-medium-port-gore-17px">A confirmation email will be sent to your inbox after submission. Be sure to check your spam as well.</p>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                    <br/>      
                </Container>
            </div>
        );
    }
}

export default ContactPage;