import React from "react";
import { useHistory } from "react-router-dom"
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import ReactGA from 'react-ga'


const Auth0ProviderWithHistory = ({ children }) => {
    const history = useHistory();
    const { user } = useAuth0();
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_CLIENT_ID
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE

    const onRedirectCallback = (appState) => {
        history.push({pathname:'/home'})
    };

    ReactGA.set({
        userId: user
    });

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
        >
            {children}
        </Auth0Provider>

    )
};

export default Auth0ProviderWithHistory