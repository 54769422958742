import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, withStyles } from "@material-ui/core";

const LogoutButton = () => {
    const { logout } = useAuth0();
    const StyledButton = withStyles ({
        root: {
          background: '#cc5454',
          borderRadius: 3,
          border: 0,
          color: 'white',
          width: 98,
          height: 48,
          padding: '0 30x',
          boxShadow: '0 3px 5px 2px rgba(80,80,80, .3)',
          "&:hover": {
                background: '#cc5454',
                opacity: "0.8"
          }
        },
        label: {
            textTransform: 'capitalize',
            fontFamily: 'Raleway',
            color: '#221f4b'
            
        },
    })(Button)
    return (
        <StyledButton
        onClick={() => logout()}
        id="qsLogoutBtn"
        className="btn-margin"
        >
            LOGOUT
        </StyledButton>
    );
};
export default LogoutButton