/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { faAt, faBullhorn, faChartPie, faCubes, faHandHoldingUsd, faPaintBrush, faPhone, faTv, faUsers, } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./homepage.css"
import Introtext from '../../components/intro-text/Intro-text';
import { Container, Row, Col } from 'react-bootstrap';
import { Input } from "@material-ui/core"
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import ReactGA from 'react-ga'


class Homepage extends React.Component {

  handleSearch = event => {
    if (event.keyCode === 13) {
        this.setState({results: []})
        ReactGA.event({
          category: 'Search',
          action: 'Used Search Function',
          label: event.target.value.toLowerCase()
      })
        axios.post('https://du4o9s7itiw77.cloudfront.net/vendors/2819612348796',
     { searchType : "tags", queryText: event.target.value.toLowerCase() })
            .then (res => {
                const results = res.data
                console.log(results)
                this.props.history.push({
                  pathname:"/search",
                  state: {
                    searchQuery: event.target.value.toLowerCase(),
                    results: results
                }
                })
            }
        )
    }
}


  handleCategory = (category, subCategory) => {
    
    let searchType
    let query

    if (subCategory === null) {
      searchType = "category"
      query = category.replace(/[^A-Z0-9]/ig, "").toLowerCase()
    } else {
      console.log('hello')
      searchType = "sub_category" 
      query = subCategory.replace(/[^A-Z0-9]/ig, "").toLowerCase()
    }

    console.log(query)

    ReactGA.event({
      category: 'Search',
      action: 'Clicked a Vendor Category',
      label: query
  })

    axios.post('https://du4o9s7itiw77.cloudfront.net/vendors/2819612348796',
     { searchType : searchType, queryText: query })
            .then (res => {
                const results = res.data
                console.log(res)
                this.props.history.push({
                  pathname:"/category",
                  state: {
                    category: category,
                    subCategory: subCategory,
                    results: results
                }
                })
            })
            .catch(error => console.log(error))
    }
                  
                
  render() {
    return (
      <Container fluid className="homepage">
        <Introtext></Introtext>
            <Row>
                <Input className="search-field-home"
                    variant="filled" 
                    placeholder="Search"
                    onKeyDown={this.handleSearch}>
                </Input>
            </Row>
            <Row noGutters sm={1} className="all-blocks">
              <Col md={4} sm={12} className="flex-col-1">
                <div className="e-mail-block">
                  <div className="icon fontawesome5free-solid-normal-white-53px"><FontAwesomeIcon icon={faAt} /></div>
                  <span className="e-mail raleway-extra-bold-white-42px" href="#" onClick={() => this.handleCategory('E-MAIL', null)}>E-MAIL</span>
                  <span className="grow-my-list raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('E-MAIL', 'GROW MY LIST')}>GROW MY LIST &gt;&gt;&gt;</span>
                  <span className="text-10 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('E-MAIL', 'CLICK TO EMAIL YOUR ELECTED OFFICIAL')}>CLICK TO EMAIL YOUR ELECTED OFFICIAL  &gt;&gt;&gt;</span>
                  {/* <span className="text-2-mobile raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('E-MAIL', 'CLICK TO EMAIL YOUR ELECTED OFFICIAL')}>EMAIL YOUR ELECTED OFFICIAL >>></span> */}
                  <span className="text-10 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('E-MAIL', 'VERIFY YOUR EMAIL LIST')}>VERIFY YOUR EMAIL LIST &gt;&gt;&gt;</span>
                  {/* <span className="text-4 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('E-MAIL', 'CHECK YOUR SPAM SCORE')}>CHECK YOUR SPAM SCORE &gt;&gt;&gt;</span> */}
                  <span className="text- raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('E-MAIL', 'BUILD A PETITION PAGE')}>BUILD A PETITION PAGE &gt;&gt;&gt;</span>
                </div>
                <div className="fundraising-block">
                  <div className="fundraising-icon fontawesome5free-solid-normal-white-53px"><FontAwesomeIcon icon={faHandHoldingUsd} /></div>
                  <span className="fundraising raleway-extra-bold-white-42px" href="#" onClick={() => this.handleCategory('FUNDRAISING', null)}>FUNDRAISING</span>
                  <span className="run-calltime raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('FUNDRAISING', "RUN CALLTIME")}>RUN CALLTIME &gt;&gt;&gt;</span>
                  <span className="text-10 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('FUNDRAISING', 'VIRTUAL FUNDRAISING EVENTS')}>RUN VIRTUAL FUNDRAISING EVENTS &gt;&gt;&gt;</span>
                </div>
                <div className="testing-block">
                  <div className="testing-icon fontawesome5free-solid-normal-white-53px"><FontAwesomeIcon icon={faCubes} /></div>
                  <span className="testing raleway-extra-bold-white-42px" href="#" onClick={() => this.handleCategory('TESTING', null)}>TESTING</span>
                  <span className="digital-testing raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('TESTING', 'DIGITAL TESTING')}>DIGITAL TESTING &gt;&gt;&gt;</span>
                  <span className="e-mail-testing raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('TESTING', 'MESSAGE TESTING')}>MESSAGE TESTING &gt;&gt;&gt;</span>
                </div>
              </Col>
              <Col md={4} sm={12} className="flex-col-2">
                <div className="phones-block">
                  <div className="phones-icon fontawesome5free-solid-normal-white-53px"><FontAwesomeIcon icon={faPhone} /></div>
                  <span className="phones raleway-extra-bold-white-42px" href="#" onClick={() => this.handleCategory('PHONES', null)}>PHONES</span>
                  <span className="text-6 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('PHONES', 'PHONE BANKING')}>PHONE BANKING &gt;&gt;&gt;</span>
                  <span className="text-7 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('PHONES', 'TEXTING SERVICES')}>FIND A TEXTING SERVICE &gt;&gt;&gt;</span>
                </div>
                <div className="find-talent-block">
                  <div className="talent-icon fontawesome5free-solid-normal-white-53px"><FontAwesomeIcon icon={faUsers} /></div>
                  <span className="find-talent raleway-extra-bold-white-42px" href="#" onClick={() => this.handleCategory('FIND TALENT', null)}>FIND TALENT</span>
                  <span className="campaign-staff raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('FIND TALENT', 'CAMPAIGN STAFF')}>CAMPAIGN STAFF &gt;&gt;&gt;</span>
                  <span className="text-10 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('FIND TALENT', 'CAMPAIGN VOLUNTEERS')}>CAMPAIGN VOLUNTEERS &gt;&gt;&gt;</span>
                  <span className="text-10 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('FIND TALENT', 'GRAPHIC DESIGNERS')}>GRAPHIC DESIGNERS &gt;&gt;&gt;</span>
                  <span className="text-11 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('FIND TALENT', 'VOICEOVER TALENT')}>VOICEOVER TALENT &gt;&gt;&gt;</span>
                </div>
                <div className="digital-ads-block">
                  <div className="digital-ads-icon fontawesome5free-solid-normal-white-53px"><FontAwesomeIcon icon={faTv} /></div>
                  <span className="digital-ads raleway-extra-bold-white-42px" href="#" onClick={() => this.handleCategory('DIGITAL ADS', null)}>DIGITAL ADS</span>
                  <span className="text-14 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('DIGITAL ADS', "RUN A DIGITAL ADS CAMPAIGN")}>RUN A DIGITAL ADS CAMPAIGN &gt;&gt;&gt;</span>
                  <span className="text-10 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('DIGITAL ADS', 'SOCIAL LISTENING')}>RUN SOCIAL LISTENING CAMPAIGN &gt;&gt;&gt;</span>
                  {/* <span className="analyze-news raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('DIGITAL ADS', 'ANALYZE NEWS')}>ANALYZE NEWS &gt;&gt;&gt;</span> */}
                </div> </Col>
                <Col md={4} sm={12} className="flex-col">
                  <div className="digital-content-block">
                    <div className="paint-brush-icon fontawesome5free-solid-normal-white-53px"><FontAwesomeIcon icon={faPaintBrush} /></div>
                    <span className="digital-content raleway-extra-bold-white-42px" href="#" onClick={() => this.handleCategory('DIGITAL CONTENT', null)}>DIGITAL CONTENT</span>
                    {/* <span className="text-8 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('DIGITAL CONTENT', "CREATE A WEBSITE")}>CREATE A WEBSITE &gt;&gt;&gt;</span> */}
                    <span className="text-8 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('DIGITAL CONTENT', 'CREATE GRAPHICS')}>CREATE GRAPHICS &gt;&gt;&gt;</span>
                    <span className="text-9 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('DIGITAL CONTENT', 'TRANSCRIPTION SERVICES')}>ADD SUBTITLES TO A VIDEO &gt;&gt;&gt;</span>
                  </div>
                  <div className="data-research-block">
                    <div className="text-12 fontawesome5free-solid-normal-white-53px"><FontAwesomeIcon icon={faChartPie} /></div>
                    <span className="data-research raleway-extra-bold-white-42px" href="#" onClick={() => this.handleCategory('DATA & RESEARCH', null)}>DATA &amp; RESEARCH</span>
                    <span className="text-13 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('DATA & RESEARCH', 'BUY A LIST OF VOTERS')}>BUY A LIST OF VOTERS &gt;&gt;&gt;</span>
                    <span className="conduct-a-poll raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('DATA & RESEARCH', 'CONDUCT A POLL')}>CONDUCT A POLL &gt;&gt;&gt;</span>
                  </div>
                  <div className="mobilization-block">
                    <div className="mobilization-icon fontawesome5free-solid-normal-white-53px"><FontAwesomeIcon icon={faBullhorn} /></div>
                    <span className="mobilization raleway-extra-bold-white-42px" href="#" onClick={() => this.handleCategory('MOBILIZATION', null)}>MOBILIZATION</span>
                    <span className="text-16 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('MOBILIZATION', 'FIND ACTIVISTS ONLINE')}>FIND ACTIVISTS ONLINE &gt;&gt;&gt;</span>
                    <span className="text-17 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('MOBILIZATION', 'FIND INFLUENCERS ONLINE')}>FIND INFLUENCERS ONLINE &gt;&gt;&gt;</span>
                    <span className="text-1-1 raleway-medium-white-17px" href="#" onClick={() => this.handleCategory('MOBILIZATION', 'RELATIONAL ORGANIZING')}>RELATIONAL ORGANIZING &gt;&gt;&gt;</span>
                  </div>
              </Col>
            </Row>
      </Container>
  )};
}

export default withRouter(Homepage);



