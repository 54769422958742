import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithHistory from "./auth0-provider-with-history"
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from "history"
import ReactGA from 'react-ga'
import ScrollToTop from './scroll-to-top';

// eslint-disable-next-line react-hooks/rules-of-hooks
const history = createBrowserHistory();

const trackingId = "UA-199340948-2"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <ScrollToTop>
      <Auth0ProviderWithHistory>
          <App />
      </Auth0ProviderWithHistory>
      </ScrollToTop>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
