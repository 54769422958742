import React, { Component } from 'react';
import './vendor-box.css'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import VendorModal from './vendor-modal/vendor-modal'
import ReactGA from 'react-ga'


class VendorBox extends Component {
    constructor(props) {
        super(props)
        this.state.vendorName = props.vendorName
        this.state.vendorUrl = props.vendorUrl
        this.state.logoS3= props.logoS3
        this.state.tagline = props.tagline
        this.state.mission = props.mission
    }
    
    state = {
        vendorName: null,
        vendorUrl: null,
        logoS3: null,
        tagline: null,
        mission: null
    }

    handleGoToSite = () => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Clicked Vendor Site Link',
            label: this.state.vendorName
        })
    }

    render() {
        return ( 
            <Card className='vendorbox'>
                <CardActionArea href={this.state.vendorUrl} target="_blank">
                    <div className="img-container">
                    <CardMedia
                        className='card-img'
                        component="img"
                        alt={this.state.vendorName}
                        height="140"
                        image={this.state.logoS3}
                        title="vendorLogo"
                    />
                    </div>
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {this.state.vendorName}
                    </Typography>
                    <Typography className='tagline' variant="body2" color="textSecondary" component="p" >
                        {this.state.tagline}
                    </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button target="_blank" size="small" color="primary" href={this.state.vendorUrl} onClick={this.handleGoToSite}>
                    Go to site
                    </Button>
                        <VendorModal name={this.state.vendorName} />
                </CardActions>
                </Card>
                );
    }
}

export default VendorBox;