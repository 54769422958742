import React from 'react'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./search-text.css"
import { Col, Row, Container } from 'react-bootstrap'

class SearchText extends React.Component {
    render() {
        return (
            <Container fluid className>
                {/* <div className="intro-text">
                <div className="our-tools-intro"> */}
                <Row noGutters className="intro-and-icon-row-desktop">
                    <Col className="intro-and-icon-col-desktop">
                        <h1 className="title raleway-black-port-gore-100px">SEARCH</h1>
                        <div className="search-icon"><FontAwesomeIcon icon={faSearch} /> </div>
                    </Col>
                </Row>
                <Row fluid className="search-intro-row-mobile">
                    <Col className="intro-col-mobile"><h1 className="title raleway-black-port-gore-100px search">SEARCH</h1></Col>
                </Row>
                <Row className="icon-row-mobile">
                    <Col className="tools-icon-mobile"><FontAwesomeIcon icon={faSearch} /></Col>
                </Row>
            </Container>
        )
    }
} 

export default SearchText