import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./non-mobile.css";

export default function NonMobile() {
  return (
    <div className="container-landing">
      <div className="animated-text-col" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p className="raleway-white-84px drop-shadow">BUILD BLUE <span className="kristi-regular-165px">tools</span> </p>
      </div>

      {/*
      <!-- Changelog: Jim - commented out sliding text --> 
      <div className="animated">
        <div class="slidingVertical raleway-black-port-gore-84px drop-shadow">
          <div>Fundraising</div>
          <div>Mobilization</div>
          <div>Digital</div>
          <div>Data</div>
          <div>Testing</div>
          <div>TOOLS</div>
        </div>
      </div>

      <!-- Changelog: Jim - Added landing-page-text-x classes--> 
      */}
      <div className="main-content" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="subtext-1 raleway-white-32px drop-shadow landing-page-text-1" >
        Build Blue Tools helps campaigns find the right tools, platforms, and
        people to <span className="yellow-text-one">develop a digital infrastructure </span> that <span className="yellow-text-two"> mobilizes supporters </span> and
        <span className="yellow-text-three"> builds a powerful base. </span> All in one place. 
      </div>
      <div className="subtext-2 raleway-white-32px drop-shadow landing-page-text-2">
        We’re here to help campaigns spend less time searching, and more time
        building movements.
      </div>
      <div className="button-col">
        <Link to="/home">
          <Button className="bb-btn" to="/home">
            BUILD BLUE NOW
          </Button>
        </Link>
      </div>
      </div>
      {/* 
      <!-- Changelog: Jim - commented out icon in middle of page --> 
      <div className="landing-icon-col">
        <img
          src="https://bbt-reference-public.s3.us-east-2.amazonaws.com/build-blue-tools.png"
          alt=""
          className="landing-page-icon"
        />
      </div> */}
    </div>
  );
}
