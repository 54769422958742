import React from 'react';
import { makeStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Button from '@material-ui/core/Button';
import axios from 'axios'
import "./vendor-modal.css"
import ReactGA from 'react-ga'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "25px",
        width: "600px",
        border: '2px solid #221f4b',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    banner: {
        width: "100%",
        display: 'block',
        textAlign: 'center',
    },
    }));

    

export default function VendorModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [results, setResults] = React.useState(['']);
    // const [vendorUrl, setVendorUrl] = React.useState(null);
    // const [logoS3, setLogoS3] = React.useState(null);
    // const [services, setServices] = React.useState("null");
    // const [mission, setMission] = React.useState(null);
    
    const handleOpen = () => {
        setOpen(true);
        axios.post('https://du4o9s7itiw77.cloudfront.net/vendors/2819612348796',
         { searchType : "name", queryText: props.name })
                .then (res => {
                    const results = res.data
                    setResults(results)
                }
            )
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleVisitSite = () => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Clicked Vendor Site Link',
            label: this.state.vendorName
        })
    }
    
    return (
        <div>
        <Button size="small" color="primary" onClick={handleOpen}>
            Learn More
        </Button>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={open}>
            <div className={classes.paper}>
                <p className="raleway-medium-port-gore-32px">{results[0].vendorName}</p>
                <br />
                <div className={classes.banner}><img className={classes.logo} class='img-responsive center-block' src={results[0].logoS3} alt="Organization logo" /></div>
                <hr />
                <p className="raleway-medium-port-gore-32px">Services:</p>
                <br />
                <p className="raleway-medium-port-gore-17px">{results[0].services}</p>
                <br />
                <p className="raleway-medium-port-gore-32px">Mission:</p>
                <br />
                <p className="raleway-medium-port-gore-17px">{results[0].mission}</p>
                <br />
                <br />
                <Button variant="outlined" color="primary" href={results[0].vendorUrl} target="_blank" rel="noopener noreferrer" onClick={handleVisitSite}>Visit Site</Button>
            </div>
            </Fade>
        </Modal>
        </div>
    );
    }