import React from 'react'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./contact-text.css"
import { Col, Row, Container } from 'react-bootstrap'

class ContactText extends React.Component {
    render() {
        return (
            <Container>
                {/* <div className="intro-text">
                <div className="our-tools-intro"> */}
                <Row noGutters className="intro-and-icon-row-desktop">
                    <Col className="intro-and-icon-col-desktop">
                        <h1 className="raleway-black-port-gore-100px title">CONTACT US</h1>
                        <div className="search-icon"><FontAwesomeIcon icon={faGlobe} /> </div>
                    </Col>
                </Row>
                <Row className="intro-row-mobile">
                    <Col className="intro-col-mobile"><h1 className="raleway-black-port-gore-72px title">CONTACT US</h1></Col>
                </Row>
                <Row className="icon-row-mobile">
                    <Col className="tools-icon-mobile"><FontAwesomeIcon icon={faGlobe} /></Col>
                </Row>
            </Container>
        )
    }
} 

export default ContactText