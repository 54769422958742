import React from 'react'
import { faTools } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./Intro-text.css"
import { Col, Row, Container } from 'react-bootstrap'

class Introtext extends React.Component {
    render() {
        return (
            <Container>
                {/* <div className="intro-text">
                <div className="our-tools-intro"> */}
                <Row noGutters className="intro-and-icon-row-desktop">
                    <Col className="intro-and-icon-col-desktop">
                        <h1 className="title raleway-black-port-gore-100px">TOOLS DASHBOARD</h1>
                        <div className="tools-icon"><FontAwesomeIcon icon={faTools} /> </div>
                    </Col>
                </Row>
                <Row className="intro-row-mobile">
                    <Col className="intro-col-mobile"><h1 className="title raleway-bold-port-gore-42px">TOOLS DASHBOARD</h1></Col>
                </Row>
                <br />
                <Row className="icon-row-mobile">
                    <Col className="tools-icon-mobile"><FontAwesomeIcon icon={faTools} /></Col>
                </Row>
                {/* </div>
                <div className="text-1 raleway-medium-port-gore-32px"> */}
                <Row className="span0-row">
                    <Col className="span0-col">
                        <p className="span0">
                            Plugging you into digital tools you need to power progressive change.
                        </p>
                    </Col>
                </Row>
                {/* </div>
            </div> */}
            </Container>
        )
    }
} 

export default Introtext