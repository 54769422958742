import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap'
import "./privacy-policy.css"

class PrivacyPolicy extends Component {
    render() {
        return (
            <Container fluid>
                <Row noGutters className="intro-and-icon-row-desktop">
                    <Col className="intro-and-icon-col-desktop">
                        <h1 className="title raleway-black-port-gore-100px">BUILD BLUE TOOLS</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className='sub-text span0'>
                        Privacy Policy and Terms of Use
                    </Col>
                </Row>
                <br />
                <hr width="800px"/>
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">1. INTRODUCTION</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        This website, Build Blue Tools (the “Site”) is operated by Build Blue LLC (“We” or “Us”).
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        By logging into this website and using the Site, you agree to be legally bound to by this
                        Privacy Policy and Terms of Use (collectively, “Terms”). You also agree to be bound by
                        other legal notices that may be posted on this Site from time to time.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        We are committed to protecting your privacy online. By providing Personal Information to us, you agree to these Terms.
                    </p>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">2. UPDATES TO THE TERMS</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        Please review these Terms periodically because we modify it from time to time.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        Please look at the “LAST UPDATED” legend at the top of this page to see when these Terms
                        were last revised. Any changes to these Terms will become effective when we post the
                        revised Terms on the Site.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        Each time you visit this Site or provide us with information through the Site, by doing so you
                        are accepting practices described in the Terms in effect at that time.
                    </p>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">3. INTELLECTUAL PROPERTY RIGHTS</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        All copyright and other rights in and to the computer programs, computer applications, web
                        applications, database programs, web content files and all written, graphic, audio and
                        audiovisual works incorporated into and utilized on or a part of the Site will be and remain
                        the sole and exclusive property of Build Blue (“BBT Intellectual Property”). You may not
                        copy, modify, publish, transmit, distribute, perform, display, or sell any such BBT
                        Intellectual Property.
                    </p>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">4. YOUR PERSONAL INFORMATION</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        "Personal Information" is information that identifies you as an idividual such as:
                    </p>
                    <br />
                    <ul className="raleway-medium-port-gore-17px ">
                        <li className='privacy-li'>Name</li>
                        <li className='privacy-li'>Email Address</li>
                        <li className='privacy-li'>IP Address</li>
                    </ul>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        We collect information you give us when you register on our site or contact us. Build Blue
                        does not collect credit or debit card information.
                    </p>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">5. HOW WE USE PERSONAL INFORMATION</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        We may use Personal Information:
                    </p>
                    <br />
                    <ul className="raleway-medium-port-gore-17px ">
                        <li className='privacy-li'>
                            To respond to your inquiries and fulfill your requests, such as to send you updates by
                            e-mail. (For information about how to unsubscribe from certain e-mails, please see
                            the “Choices and Access” section below.)
                        </li>
                        <li className='privacy-li'>
                            To send you important information regarding Build Blue, the Site, changes to our
                            terms, conditions, and policies and/or other administrative information.
                        </li>
                        <li className='privacy-li'>
                            For our business purposes, such as data analysis, enhancing our website, improving
                            our services, identifying usage trends and determining the effectiveness of our
                            services.
                        </li>
                        <li className='privacy-li'>
                            To process your donation or payment.
                        </li>
                        <li className='privacy-li'>
                            As we believe to be necessary or appropriate: (a) under applicable law, including laws
                            outside your country of residence; (b) to comply with legal process; (c) to respond to
                            requests from public and government authorities including public and government
                            authorities outside your country of residence; (d) to enforce our terms and conditions;
                            (e) to protect our operations or those of any of our affiliates; (f) to protect our rights,
                            privacy, safety or property, and/or that of our affiliates, you or others; and (g) to allow
                            us to pursue available remedies or limit the damages that we may sustain.
                        </li>
                    </ul>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">6. HOW PERSONAL INFORMATION IS DISCLOSED</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        Your Personal Information may be disclosed:
                    </p>
                    <br />
                    <ul className="raleway-medium-port-gore-17px ">
                        <li className='privacy-li'>
                            To third parties when you voluntarily provide such information or when you use
                            certain services available on the Site (such as sending messages to third parties or
                            completing surveys).
                        </li>
                        <li className='privacy-li'>
                            To our third-party service providers who provide services such as website hosting,
                            data analysis, donation and payment processing, order fulfillment, infrastructure provision, 
                            IT services, customer service, email delivery services, auditing services
                            and other similar services to enable them to provide services.
                        </li>
                        <li className='privacy-li'>
                            To our affiliates to permit them to send you communications.
                        </li>
                        <li className='privacy-li'>
                            To a third party in the event of any reorganization, merger, sale, joint venture,
                            assignment, transfer or other disposition of all or any portion of our assets (including
                            in connection with any bankruptcy or similar proceedings).
                        </li>
                        <li className='privacy-li'>
                            As we believe to be necessary or appropriate: (a) under applicable law (b) to comply
                            with legal process; (c) to respond to requests from public and government authorities;
                            (d) to enforce our Terms; (e) to protect our operations or those of any of our affiliates;
                            (f) to protect our rights, privacy, safety or property, and/or that of our affiliates, you
                            or others; and (g) to allow us to pursue available remedies or limit the damages that
                            we may sustain.
                        </li>
                    </ul>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">7. OTHER INFORMATION</h2>
                    <br />
                    <ol className="raleway-black-port-gore-24px">
                        <li>a. OTHER INFORMATION WE COLLECT</li>
                            <br />
                            <br />
                            <p className="raleway-medium-port-gore-17px ">
                                “Other Information” is any information that does not reveal your specific identity or
                                does not directly relate to an individual, such as:
                            </p>
                            <br />
                            <ul className="raleway-medium-port-gore-17px ">
                                <li className='privacy-li'>
                                    Browser information
                                </li>
                                <li className='privacy-li'>
                                    Server log files
                                </li>
                                <li className='privacy-li'>
                                    Information collected through cookies, pixel tags and other technologies
                                </li>
                                <li className='privacy-li'>
                                    Demographic information and other information provided by you
                                </li>
                                <li className='privacy-li'>
                                    Aggregated information
                                </li>
                            </ul>
                        <br />
                        <li>b. HOW WE COLLECT OTHER INFORMATION</li>
                            <br />
                            <br />
                            <p className="raleway-medium-port-gore-17px ">
                                We and our third party service providers may collect Other Information in a variety of ways, including:
                            </p>
                            <br />
                            <ul className="raleway-medium-port-gore-17px ">
                                <li className='privacy-li'>
                                    Through your browser: Certain information is collected by most browsers,
                                    such as your Media Access Control (MAC) address, computer type (Windows
                                    or Macintosh), screen resolution, operating system version and Internet
                                    browser type and version.
                                </li>
                                <li className='privacy-li'>
                                    Using cookies: Cookies allow a web server to transfer data to a computer for
                                    record keeping and other purposes. We and our service providers use cookies
                                    and other technologies to, among other things, better serve you with more
                                    tailored information and facilitate your ongoing access to and use of the Site,
                                    as well as for online tracking purposes. If you do not want information
                                    collected through the use of cookies, there is a simple procedure in most
                                    browsers that allows you to decline the use of cookies. To learn more about
                                    cookies, please visit http://www.allaboutcookies.org/. If you choose to
                                    decline cookies, some or all of the features and functionality of the Site may
                                    not be available to you.
                                </li>
                                <li className='privacy-li'>
                                    Using embedded images, pixel tags and other similar technologies: We may
                                    use embedded images or pixel tags (also known as web beacons and clear
                                    GIFs) in connection with some Site pages and email messages to, among other
                                    things, track the actions of Site users and email recipients, measure the
                                    success of our marketing campaigns and compile statistics about Site usage
                                    and response rates.
                                </li>
                                <li className='privacy-li'>
                                    From you: We may collect demographic information such as your location, as
                                    well as other information, such as your preferred means of communication,
                                    when you voluntarily provide this information to us. Unless combined with
                                    Personal Information, this information does not personally identify you or any
                                    other user of the Site.
                                </li>
                                <li className='privacy-li'>
                                    By aggregating information: We may aggregate Personal Information so that
                                    the end-product does not personally identify you or any other user of the Site,
                                    for example, by using Personal Information to calculate the percentage of our
                                    users who have a particular telephone area code. Aggregated Personal
                                    Information does not personally identify you or any other user of the Site.
                                </li>
                            </ul>
                            <br />
                        <li>c. HOW WE USE AND DISCLOSE OTHER INFORMATION</li>
                            <br />
                            <br />
                            <p className="raleway-medium-port-gore-17px ">
                                Because Other Information does not personally identify you, we may use and disclose Other Information for any purpose.
                            </p>
                            <br />
                            <p className="raleway-medium-port-gore-17px ">
                                In some instances, we may combine Other Information with Personal Information
                                (such as combining your name with your IP Address). If we combine any Other
                                Information with Personal Information, the combined information will be treated by
                                us as Personal Information as long as it is combined.
                            </p>
                    </ol>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">8. USE OF BUILD BLUE TOOLS</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        The purpose of the Site is to provide a centralized database of Democratic political vendors
                        throughout the country. You may not sell or offer to sell access to Site, make any
                        commercial use of the Site, or information that you obtain in the course of using Site.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        You agree not to use the Site to:.
                    </p>
                    <br />
                    <ul className="raleway-medium-port-gore-17px ">
                        <li className='privacy-li'>
                            forward personally identifiable information about any person to any other person;
                        </li>
                        <li className='privacy-li'>
                            engage in advertising to, or solicitation of, any other persons to buy, or sell any
                            product or services, including posting advertisements and/or solicitations of business;
                        </li>
                        <li className='privacy-li'>
                            use this Site in a manner inconsistent with any and all applicable laws and regulations of any country;
                        </li>
                        <li className='privacy-li'>
                            impersonate any person or entity, real of fictitious, or falsely state or otherwise misrepresent your affiliation with a person or entity;
                        </li>
                        <li className='privacy-li'>
                            stalk, abuse (or attempt to abuse), or otherwise harass another person;               
                        </li>
                        <li className='privacy-li'>
                            make unauthorized use of any Build Blue trademarks or service marks;             
                        </li>
                        <li className='privacy-li'>
                            upload, post, e-mail or otherwise transmit or create any content that contain any
                            viruses, Trojan horses, worms, spyware, time bombs, cancel bots or other computer
                            programming routines that are intended to damage, detrimentally interfere with,
                            surreptitiously intercept or expropriate any system, data or personal information;           
                        </li>
                        <li className='privacy-li'>
                            upload, post, email or otherwise transmit any unsolicited or unauthorized advertising,
                            or promotional materials, that are in the nature of &quot;junk mail,&quot; &quot;spam,&quot; &quot;chain letters,&quot;
                            &quot;pyramid schemes,&quot; or any other form of solicitation;          
                        </li>
                        <li className='privacy-li'>
                            interfere with or disrupt the Site or Site servers or networks connected to the Site, or
                            disobey any requirements, procedures, policies or regulations of networks connected
                            to the Site;             
                        </li>
                        <li className='privacy-li'>
                            attempt to gain access to any other person’s account or password;             
                        </li>
                        <li className='privacy-li'>
                            include links to personal or commercial web pages and/or websites that we, in our
                            sole discretion, deem incompatible with the goals and/or philosophy of Build Blue;           
                        </li>
                        <li className='privacy-li'>
                            provide information that you know is false or misleading;        
                        </li>
                        <li className='privacy-li'>
                            post or provide links to your or other persons’ passwords;        
                        </li>
                        <li className='privacy-li'>
                            post or provide links to information relating to circumventing this Site&#39;s or any other
                            website&#39;s protected software; or        
                        </li>
                        <li className='privacy-li'>
                            post links to expressly solicit personal information from anyone under the age of 18.       
                        </li>
                    </ul>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">9. LIMITATION ON LIABILITY</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        We reserve the right to interrupt the operation of Site, or your use of the Site, with or without
                        prior notice for any reason or no reason.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        You agree that We will not be liable for interruption of Site or for its delay or failure to
                        perform. We assume no responsibility for any error, omission, interruption, deletion, defect,
                        delay in operation or transmission, telephone system or communications lines failures, or
                        theft or destruction or unauthorized access to, or alteration of, user communications.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        We are not responsible for any problems or technical malfunction of any telephone network
                        or lines, computer systems, servers or providers, computer equipment, software, failure of
                        email systems on account of technical problems or traffic congestion on the Internet or at any
                        site or combination thereof, including injury or damage to users of Site or to any other
                        person&#39;s phone or computer related to or resulting from participating or downloading
                        materials in connection with Site.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        Except where restricted or prohibited, in no event will We be liable to you or any third
                        person for any indirect, consequential, exemplary, incidental, special or punitive damages,
                        including lost profits arising from your participation in Site or use of the Site, or of our
                        termination of your account, even if We have been advised of the possibility of such
                        damages.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        You understand that we cannot and do not guarantee or warrant that files available for
                        downloading from the internet or the Website will be free of viruses or other destructive
                        code. You are responsible for implementing sufficient procedures and checkpoints to satisfy
                        your particular requirements for anti-virus protection and accuracy of data input and output,
                        and for maintaining a means external to our site for any reconstruction of any lost data.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR
                        ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
                        ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL
                        THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
                        DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
                        WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR
                        TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
                        WEBSITE LINKED TO IT.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        YOUR USE OF THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
                        OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. THE SITE, ITS
                        CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE
                        PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY
                        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WE DO NOT MAKE
                        ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                        COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                        AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, WE DO
                        NOT REPRESENT OR WARRANT THAT THE SITE, ITS CONTENT, OR ANY
                        SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE,
                        RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                        CORRECTED, THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE
                        ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SITE
                        OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE
                        MEET YOUR NEEDS OR EXPECTATIONS.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        TO THE FULLEST EXTENT PROVIDED BY LAW, WE DISCLAIM ALL
                        WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
                        OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                        MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
                        PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT
                        CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                    </p>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">10. SECURITY</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        We use reasonable organizational, technical and administrative measures to protect Personal
                        Information under our control. Unfortunately, no data transmission over the Internet can be
                        guaranteed to be 100% secure. If you have reason to believe that your interaction with us is
                        no longer secure, please immediately notify us of the issue by contacting us in accordance
                        with the “Contacting Us” section below.
                    </p>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">11. ONLINE PRIVACY PROTECTION</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        We comply with the California Online Privacy Protection Act. As part of that Act, all
                        registered users of our site may make any changes to their information at any time contacting
                        us as described below.
                    </p>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">12. CHOICES AND ACCESS</h2>
                    <br />
                    <ol className="raleway-black-port-gore-24px">
                        <li>a. YOUR CHOICES REGARDING OUR USE AND DISCLOSURE OF YOUR PERSONAL INFORMATION</li>
                            <br />
                            <br />
                            <p className="raleway-medium-port-gore-17px ">
                                We give you many choices regarding our use and disclosure of your Personal
                                Information. You may opt out from:
                            </p>
                            <br />
                            <ul className="raleway-medium-port-gore-17px ">
                                <li className='privacy-li'>
                                    Receiving campaign or marketing-related emails from us: If you no longer
                                    want to receive campaign or marketing-related emails from us on a going-
                                    forward basis, you may email info@buildbluetools.org.
                                </li>
                                <li className='privacy-li'>
                                    Our sharing of your Personal Information with affiliates for their marketing
                                    purposes: If you would prefer that we do not share your Personal Information
                                    on a going-forward basis with our affiliates for their marketing purposes, you
                                    may opt out of this sharing by contacting us at info@buildbluetools.org.
                                </li>
                                <br />
                                <p className="raleway-medium-port-gore-17px ">
                                    We will try to comply with your request(s) as soon as reasonably practicable.
                                    Please note that if you opt out as described above, we will not be able to
                                    remove your Personal Information from the databases of our affiliates or
                                    unaffiliated third parties with which we have already shared your Personal
                                    Information (i.e., to which we have already provided your Personal
                                    Information as of the date that we implement your opt out request).
                                </p>
                                <br />
                                <p className="raleway-medium-port-gore-17px ">
                                    Please also note that if you do opt out of receiving marketing-related messages
                                    from us, we may still send important administrative messages to you, and you
                                    cannot opt out from receiving administrative messages.
                                </p>
                            </ul>
                        <br />
                        <li>b. HOW YOU MAY ACCESS OR CHANGE YOUR PERSONAL INFORMATION</li>
                            <br />
                            <br />
                            <p className="raleway-medium-port-gore-17px ">
                                If you would like to review or update your Personal Information that has been
                                previously provided to us, you may contact us at info@buildbluetools.org.
                            </p>
                            <br />
                            <p className="raleway-medium-port-gore-17px ">
                                In your request, please make clear what information you would like to have changed.
                                We will try to comply with your request as soon as reasonably practicable. We are not
                                responsible for changing information from the databases of third parties with whom
                                we have already shared your Personal Information
                            </p>
                    </ol>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">13. THIRD-PARTY SITES</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        These Terms does not address, and we are not responsible for, the privacy, information or
                        other practices of any third parties, including any third party operating any site to which this
                        Site contains a link. The inclusion of a link on the Site does not imply endorsement of the
                        linked site by us or by our affiliates.
                    </p>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">14. USE OF SITE BY MINORS</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        The Site is not directed to individuals under the age of thirteen, and we request that these
                        individuals do not provide Personal Information through the Site.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        We do not knowingly, directly or passively, collect information from children under the age of 13.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        To respect the privacy of children and to comply with the Children&#39;s Online Privacy
                        Protection Act, children under the age of 13 should not provide any Personal Information on
                        this Site. We ask that parents supervise their children while online.
                    </p>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">15. JURISDICTIONAL ISSUES</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        The Site is controlled and operated by us from the United States and is not intended to
                        subject us to the laws or jurisdiction of any state, country or territory other than that of the
                        United States.
                    </p>
                </Row>
                <br />
                <br />
                <Row className="policy-text-row">
                    <h2 className="raleway-black-port-gore-32px">16. CONTACTING US</h2>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        If you have any questions about this Privacy Policy and Terms of Use, please contact us at info@buildbluetools.org.
                    </p>
                    <br />
                    <p className="raleway-medium-port-gore-17px ">
                        Please note that email communications are not always secure, so please do not include
                        sensitive information in your messages to us.
                    </p>
                </Row>
                <br />
                <br />
                <br />
                <br />
                </Container>
        );
    }
}

export default PrivacyPolicy;