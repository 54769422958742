import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faBullhorn, faChartPie, faCubes, faHandHoldingUsd, faPaintBrush, faPhone, faTv, faUsers, } from '@fortawesome/free-solid-svg-icons'
import "./category-text.css"
import { Col, Row, Container } from 'react-bootstrap'

class CategoryText extends React.Component {
    constructor(props) {
        super(props)
        this.state.categoryText = props.category
        this.state.subCategoryText = props.subCategory
    }
    
    state = {
        categoryText: null,
        subCategoryText: null
    }

    render() {
        const categoryText = this.state.categoryText
        let subCategoryText = this.state.subCategoryText
        let CategoryIcon
        let MobileTitle
        let CategoryCopy

        // This block of code conditionally assigns the above variables with JSX according to the currently selected category/sub-category
        switch (categoryText) {
    
            // All potential email sub categories
            case 'E-MAIL':
                CategoryIcon = <FontAwesomeIcon icon={faAt} />
                MobileTitle = <h1 className="category-title raleway-black-port-gore-72px">{this.state.categoryText}</h1>
                if (this.state.subCategoryText === "GROW MY LIST") {
                    CategoryCopy = <span>Bring in the right supporters to build your movement.</span>
                }
                else if (this.state.subCategoryText === "VERIFY YOUR EMAIL LIST") {
                    CategoryCopy = <span>Maximize your list’s experience and protect your email reputation.</span>
                }
                else if (this.state.subCategoryText === "CLICK TO EMAIL YOUR ELECTED OFFICIAL") {
                    CategoryCopy = <span>Reach elected officials, candidates, and other decision-makers with messages from supporters sent straight to their inboxes.</span>
                }
                else if (this.state.subCategoryText === "BUILD A PETITION PAGE") {
                    CategoryCopy = <span>Engage your audience, grow your list, and demonstrate public support with petitions or surveys.</span>
                }
                else {
                    subCategoryText = "ALL EMAIL TOOLS"
                    CategoryCopy = <span>Run email programs that effectively communicate to your supporters, grow your list, and drive results.</span>
                }
                break;

            // All potential fundraising sub categories
            case 'FUNDRAISING':
                CategoryIcon = <FontAwesomeIcon icon={faHandHoldingUsd} />      
                MobileTitle = <div className="category-title raleway-black-port-gore-72px fundraising-mobile">{this.state.categoryText}</div>
                if (this.state.subCategoryText === "RUN CALL TIME") {
                    CategoryCopy = <span>Manage call time for more effective fundraising efforts.</span>
                }
                else if (this.state.subCategoryText === "VIRTUAL FUNDRAISING EVENTS") {
                    CategoryCopy = <span>Launch virtual events that engage your audience and raise the funds you need.</span>
                }
                else {
                    subCategoryText = "ALL FUNDRAISING TOOLS"
                    CategoryCopy = <span>Run authentic and effective fundraising programs without breaking the bank.</span>
                }
                break;
            
            // All potential testing sub categories
            case 'TESTING':
                CategoryIcon = <FontAwesomeIcon icon={faCubes} />
                MobileTitle = <h1 className="category-title raleway-black-port-gore-72px">{this.state.categoryText}</h1>
                if (this.state.subCategoryText === "DIGITAL TESING") {
                    CategoryCopy = <span>Streamline and optimize the way you reach your digital audience.</span>
                }
                else if (this.state.subCategoryText === "MESSAGE TESTING") {
                    CategoryCopy = <span>Meet your audiences wherever they are online with ads that inspire action.</span>
                }
                else {
                    subCategoryText = "ALL TESTING TOOLS"
                    CategoryCopy = <span>Measure your impact to make data-driven decisions.</span>
                }
                break;


            // All potential phones sub categories
            case 'PHONES':
                CategoryIcon = <FontAwesomeIcon icon={faPhone} />
                MobileTitle = <h1 className="category-title raleway-black-port-gore-72px">{this.state.categoryText}</h1>
                if (this.state.subCategoryText === "PHONE BANKING") {
                    CategoryCopy = <span>Connect with voters and constituents on their mobile phones.</span>
                }
                else if (this.state.subCategoryText === "FIND A TEXTING SERVICE") {
                    CategoryCopy = <span>Peer-to-peer and broadcast text messaging tools.</span>
                }
                else if (this.state.subCategoryText === "CLICK TO CALL YOUR ELECTED OFFICIAL") {
                    CategoryCopy = <span>Connect constituents and advocates directly to elected officials or decision-makers with one click.</span>
                }
                else {
                    subCategoryText = "ALL PHONES TOOLS"
                    CategoryCopy = <span>Dial up your campaign efforts with phone and texting programs.</span>
                }
                break;


            // All potential talent sub categories
            case 'FIND TALENT':
                CategoryIcon = <FontAwesomeIcon icon={faUsers} />
                MobileTitle = <h1 className="category-title raleway-black-port-gore-72px">{this.state.categoryText}</h1>
                if (this.state.subCategoryText === "CAMPAIGN STAFF") {
                    CategoryCopy = <span>Find talented candidates to build your team.</span>
                }
                else if (this.state.subCategoryText === "CAMPAIGN VOLUNTEERS") {
                    CategoryCopy = <span>Make connections to maximize your impact with volunteers on the ground.</span>
                }
                else if (this.state.subCategoryText === "GRAPHIC DESIGNERS") {
                    CategoryCopy = <span>Find artists interested in using their talents for good.</span>
                }
                else if (this.state.subCategoryText === "VOICEOVER TALENT") {
                    CategoryCopy = <span>Find the voice to deliver your message in campaign videos.</span>
                }
                else {
                    subCategoryText = "ALL TALENT TOOLS"
                    CategoryCopy = <span>Connect with the right people to help amplify your efforts and build your movement.</span>
                }
                break;
            

            // All digital ads sub categories 
            case 'DIGITAL ADS':
                CategoryIcon = <FontAwesomeIcon icon={faTv} />
                MobileTitle = <h1 className="category-title raleway-black-port-gore-72px">{this.state.categoryText}</h1>
                if (this.state.subCategoryText === "RUN A DIGITAL ADS CAMPAIGN") {
                    CategoryCopy = <span>Tools that help educate, engage, and motivate your audiences online.</span>
                }
                else if (this.state.subCategoryText === "SOCIAL LISTENING") {
                    CategoryCopy = <span>Understand what people are talking about online and how you can join the conversation.</span>
                }
                else {
                    subCategoryText = "ALL DIGITAL ADS TOOLS"
                    CategoryCopy = <span>Meet your audiences wherever they are online with ads that inspire action.</span>
                }
                break;

            
            // All Digital content sub categories
            case 'DIGITAL CONTENT':
                CategoryIcon = <FontAwesomeIcon icon={faPaintBrush} />
                MobileTitle = <h1 className="category-title raleway-black-port-gore-72px">{this.state.categoryText}</h1>
                if (this.state.subCategoryText === "CREATE GRAPHICS") {
                    CategoryCopy = <span>Bring your campaign to life with design.</span>
                }
                else if (this.state.subCategoryText === "TRANSCRIPTION SERVICES") {
                    CategoryCopy = <span>Transcription made easy.</span>
                }
                else {
                    subCategoryText = "ALL DIGITAL CONTENT TOOLS"
                    CategoryCopy = <span>Produce thumb-stopping creative for digital audiences</span>
                }
                break;


            // All data sub categories
            case 'DATA & RESEARCH':
                CategoryIcon = <FontAwesomeIcon icon={faChartPie} />
                MobileTitle = <h1 className="category-title raleway-black-port-gore-72px">{this.state.categoryText}</h1>
                if (this.state.subCategoryText === "BUY A LIST OF VOTERS") {
                    CategoryCopy = <span>Reach new audiences and grow your list.</span>
                }
                else if (this.state.subCategoryText === "POLLING AND AUDIENCE INSIGHTS") {
                    CategoryCopy = <span>Understand who to reach and how to reach them.</span>
                }
                else {
                    subCategoryText = "ALL DATA & RESEARCH TOOLS"
                    CategoryCopy = <span>The information and insights you need to win.</span>
                }
                break;

            // All mobilization sub categories
            case 'MOBILIZATION':
                CategoryIcon = <FontAwesomeIcon icon={faBullhorn} />
                MobileTitle = <h1 className="category-title raleway-black-port-gore-72px mobilization-mobile">{this.state.categoryText}</h1>
                if (this.state.subCategoryText === "FIND ACTIVISTS ONLINE") {
                    CategoryCopy = <span>Amplify your message with authentic voices.</span>
                }
                else if (this.state.subCategoryText === "FIND INFLUENCERS ONLINE") {
                    CategoryCopy = <span>Grow and mobilize your base with influencers and validators willing to use their platform to spread your message.</span>
                }
                else if (this.state.subCategoryText === "FIND CAMPAIGN VOLUNTEERS") {
                    CategoryCopy = <span>Make connections to maximize your impact with volunteers on the ground.</span>
                }
                else {
                    subCategoryText = "ALL MOBILIZATION TOOLS"
                    CategoryCopy = <span>Increase your impact by organizing online.</span>
                }
                break;
            default:
                break;
        }

        
        return (
            <Container>
                <Row noGutters className="intro-and-icon-row-desktop">
                    <Col className="intro-and-icon-col-desktop">
                        <h1 className="title raleway-black-port-gore-100px">{this.state.categoryText}</h1>
                        <div className="search-icon">{CategoryIcon}</div>
                    </Col>
                </Row>
                <Row>
                    <br />
                    <Col className='copy roboto-black-port-gore-48px'>
                        {subCategoryText}
                    </Col>
                </Row>
                <Row className="copy">
                    <br />
                    <br />
                    <Col className='roboto-black-port-gore-30px'>
                        {CategoryCopy}
                    </Col>
                </Row>
                <Row className="intro-row-mobile">
                    <Col className="category-col-mobile">{MobileTitle}</Col>
                </Row>
                <br />
                <Row>
                    <Col className='sub-text-mobile span0'>
                        {subCategoryText}
                    </Col>
                </Row>
            </Container>
        )
    }
} 

export default CategoryText