import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import './search-page.css'
import VendorBox from '../../components/vendor-box/vendor-box'
import { Input } from "@material-ui/core"
import axios from 'axios'
import { Container, Row, Col } from 'react-bootstrap';
import SearchText from '../../components/search-text/search-text'
import ReactGA from 'react-ga'


function SearchPage() {

    const [searchQuery, setSearchQuery] = useState()
    const [samePageSearch, setSamePageSearch] = useState(false)
    const [results, setResults] = useState([])
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    

    const handleSearch = event => {
        if (event.keyCode === 13) {
            ReactGA.event({
                category: 'Search',
                action: 'Used Search Function',
                label: event.target.value.toLowerCase()
            })
            setSamePageSearch(true)
            setResults([])
            axios.post('https://du4o9s7itiw77.cloudfront.net/vendors/2819612348796',
         { searchType : "tags", queryText: event.target.value.toLowerCase() })
                .then (res => {
                    const results = res.data
                    console.log(results)
                    setSearchQuery(event.target.value.toLowerCase())
                    setResults(results)
                }
            )
        }
    }

    let resultRow
    let queryinfoRow

    if (samePageSearch === false) {
        queryinfoRow = <Row className="result-text span0">
            Search results for: "{location.state.searchQuery}"
        </Row>

        resultRow = <Row className="results">
            {location.state.results.map(result => 
                <Col className="result-card" md={4} lg={4} ><VendorBox 
                    vendorName={result.vendorName}
                    vendorUrl={result.vendorUrl}
                    logoS3={result.logoS3}
                    tagline={result.tagline}
                    mission={result.mission}
                /></Col>
            )}
        </Row>
    }
    else {
        queryinfoRow = <Row className="result-text span0">
            Search results for: "{searchQuery}"
        </Row>

        resultRow = <Row className="results">
            {results.map(result => 
                <Col className="result-card" md={4} lg={4} ><VendorBox 
                    vendorName={result.vendorName}
                    vendorUrl={result.vendorUrl}
                    logoS3={result.logoS3}
                    tagline={result.tagline}
                    mission={result.mission}
                /></Col>
            )}
        </Row>
    }
        return (
            
            <div>
                <Container>
                    <SearchText></SearchText>
                    <Row>
                        <Input className="search-field"
                            variant="filled" 
                            placeholder="Search"
                            onKeyDown={handleSearch}> 
                        </Input>
                    </Row>
                    {queryinfoRow}
                    {resultRow}
                </Container>
            </div>
        );
    }


export default SearchPage;